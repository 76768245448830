import React from "react"
import Layout from "../../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBug } from "@fortawesome/free-solid-svg-icons"

const NotFound = () => (
  <Layout>
    <div className="container mx-auto px-5">
      <h1 className="text-center">
        <FontAwesomeIcon icon={faBug} />
        <br />
        Page not found
      </h1>
    </div>
  </Layout>
)

export default NotFound
